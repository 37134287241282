import React from "react";
import styles from "./Footer.module.css";

const Footer = (props) => {
  return (
    <div className={styles.footer}>
      <p>
        📸{" "}
        <div
          style={{
            fontSize: "var(--fz-sm)",
            color: "rgba(128, 128, 128, 0.71)",
          }}
        >
          pfp creds to{" "}
          <a href="https://www.mat-wong.com/" target="_blank" rel="noreferrer">
            mat wong
          </a>
        </div>
      </p>
    </div>
  );
};

export default Footer;
