import React from "react";
import styles from "./ProjectsBox.module.css";
import SkillWidget from "./SkillWidget";
import Iframe from "react-iframe";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ProjectsBox = (props) => {
  const addLink = (textAndLinkList, description) => {
    const text = textAndLinkList[0];
    const link = textAndLinkList[1];
    let splitText = description.split(text);
    return (
      <div className={styles.descriptionStyle}>
        {splitText[0]}{" "}
        <a href={link} target="_blank" rel="noreferrer">
          {text}
        </a>
        {splitText[1]}
      </div>
    );
  };

  const skillWidgets = props.skills.map((skill) => (
    <SkillWidget skill={skill} />
  ));

  return (
    <Container>
      <div className={styles.projectsBoxContainer}>
        <Row>
          <Col sm={4}>
            <div className={styles.imgContainer}>
              {props.videoLink ? (
                <Iframe
                  url={props.videoLink}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
                  height="50%"
                  width="100%"
                />
              ) : (
                <img
                  src={props.imgSrc}
                  alt="project"
                  style={{ width: "100%" }}
                />
              )}
            </div>
          </Col>
          <Col sm={8}>
            <div className={styles.projectTitleStyle}>{props.title}</div>
            {props.link ? (
              addLink(props.link, props.description)
            ) : (
              <div className={styles.descriptionStyle}>{props.description}</div>
            )}
            <div className={styles.skillsContainer}>{skillWidgets}</div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ProjectsBox;
