import React from "react";
import styles from "./ExperienceBox.module.css";
import SkillWidget from "./SkillWidget";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ExperienceBox = (props) => {
  const skillWidgets = props.skills.map((skill) => (
    <SkillWidget skill={skill} />
  ));

  const addLink = (textAndLinkList, description) => {
    const text = textAndLinkList[0];
    const link = textAndLinkList[1];
    let splitText = description.split(text);
    return (
      <p className={styles.descriptionStyle}>
        {splitText[0]}{" "}
        <a href={link} rel="noreferrer" target="_blank">
          {text}
        </a>{" "}
        {splitText[1]}
      </p>
    );
  };

  return (
    <Container>
      <div className={styles.experienceBoxContainer}>
        <Row>
          <Col sm={4}>
            <div className={styles.dateContainer}>
              <div className={styles.dateLocationStyle}>
                <div className={styles.showMobile}>
                  {props.date} {props.location}
                </div>
                <div className={styles.hideMobile}>
                  <div>{props.date}</div>
                  <div>{props.location}</div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={8}>
            <div className={styles.positionCompanyStyle}>
              {props.position} · {props.company}
            </div>
            {props.link ? (
              addLink(props.link, props.description)
            ) : (
              <p className={styles.descriptionStyle}>{props.description}</p>
            )}

            <div className={styles.skillsContainer}>{skillWidgets}</div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ExperienceBox;
