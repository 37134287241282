import React from "react";
import styles from "./SideBarText.module.css";
import { HashLink } from "react-router-hash-link";

const SideBarText = ({ refObject }) => {
  const ids = [
    styles.about,
    styles.experience,
    styles.projects,
    styles.education,
  ];
  const texts = ["ABOUT", "EXPERIENCE", "PROJECTS", "EDUCATION"];

  const ulTags = ids.map((styleId, index) => (
    <HashLink id={styleId} smooth to={"/#" + texts[index].toLowerCase()}>
      {texts[index]}
    </HashLink>
  ));

  return <div className={styles.sideBarText}>{ulTags}</div>;
};

export default SideBarText;
