import React, { forwardRef } from "react";
import ProjectsBox from "../components/ProjectsBox";
import NLPResearch from "../static/nlpResearch.png";
import personalWebsite from "../static/personalwebsite.png";
import styles from "./Projects.module.css";

const Projects = forwardRef((props, ref) => {
  return (
    <div id="projects" ref={ref} className={styles.projectContainer}>
      <div className={styles.headerShowHide}>Projects</div>
      <ProjectsBox
        imgSrc={NLPResearch}
        title="NLP Research"
        link={[
          "github repo",
          "https://github.com/josephlee3150/twitter-topic-classifier",
        ]}
        skills={["Scikit Learn", "Python", "NLP"]}
        description="For this research project, I worked under the supervision of Dr. Jonathan K. Kummerfeld to work on a NLP model that attempts to classify politicians' tweets by topic. Learn more about my research through the github repo."
      />
      <ProjectsBox
        imgSrc={NLPResearch}
        title="Analyrical"
        videoLink="https://www.youtube.com/embed/dybY5Y72zTw"
        skills={["Flask", "HTML", "CSS", "JQuery", "MongoDB", "Selenium"]}
        description="Combining my interest in music and analytics, I developed a website that analyzes user input and the lyrics of Rolling Stone's top 200 artists and generates a ranking on which artist would be most likely to sing the user input."
      />
      <ProjectsBox
        imgSrc={personalWebsite}
        title="Personal Website"
        skills={["React", "Figma", "HTML", "CSS"]}
        description="I created this website as a portfolio to share my experiences and projects. I'm hoping to include a blog section to share some of my hobbies and photography in the future."
      />
    </div>
  );
});

export default Projects;
