import "./App.css";
import SideBar from "./components/SideBar";
import About from "./pages/About";
import Experiences from "./pages/Experiences";
import Education from "./pages/Education";
import Projects from "./pages/Projects";
import Footer from "./components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";

function App() {
  const refObject = {
    aboutRef: useRef(null),
    experiencesRef: useRef(null),
    educationRef: useRef(null),
    projectsRef: useRef(null),
    skillsRef: useRef(null),
  };

  return (
    <BrowserRouter>
      <Container className="container">
        <Row>
          <Col className="showHideSideBar" lg={12} xl={3}>
            <SideBar refObject={refObject} />
          </Col>
          <Col lg={12} xl={9}>
            <About ref={refObject.aboutRef} />
            <Experiences ref={refObject.experiencesRef} />
            <Projects ref={refObject.projectsRef} />
            <Education ref={refObject.educationRef} />
          </Col>
          <Col xs={12}>
            <Footer />
          </Col>
        </Row>
      </Container>
      <Analytics />
    </BrowserRouter>
  );
}

export default App;
