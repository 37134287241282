import React, { forwardRef } from "react";
import cseUmich from "../static/cse_umich.jpeg";
import styles from "./Education.module.css";
import SkillWidget from "../components/SkillWidget";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Education = forwardRef((props, ref) => {
  return (
    <div id="education" ref={ref} className={styles.educationContainer}>
      <div className={styles.headerShowHide}>Education</div>
      <Container>
        <div className={styles.educationBoxContainer}>
          <Row>
            <Col sm={4}>
              <div className={styles.schoolContainer}>
                <img alt="umich" style={{ width: "100%" }} src={cseUmich} />
              </div>
            </Col>
            <Col sm={8}>
              <div className={styles.descriptionContainer}>
                <div className={styles.titleStyle}>University</div>
                <p className={styles.bodyStyle}>
                  I majored in computer science at the{" "}
                  <a href="https://umich.edu/" target="_blank" rel="noreferrer">
                    University of Michigan - Ann Arbor
                  </a>{" "}
                  and I graduated from the College of Engineering in December
                  2022. Go Blue! 〽️
                </p>
                <p className={styles.bodyStyle}>Coursework: </p>
                <div className={styles.skillsContainer}>
                  <SkillWidget skill="Intro to Machine Learning" />
                  <SkillWidget skill="Computer Vision" />
                  <SkillWidget skill="Intro to Operating Systems" />
                  <SkillWidget skill="Advanced Operating Systems Special Topics" />
                  <SkillWidget skill="Intro to Computer Organization" />
                  <SkillWidget skill="User Interface Development" />
                  <SkillWidget skill="Data Structures & Algorithms" />
                  <SkillWidget skill="Applied Linear Algebra" />
                  <SkillWidget skill="Multivariable & Vector Calculus" />
                  <SkillWidget skill="Models of Social Information Processing" />
                  <SkillWidget skill="Intro to Cognitive Sciences" />
                </div>
                <p className={styles.bodyStyle} style={{ marginTop: "20px" }}>
                  Some fun classes I took:{" "}
                </p>
                <div className={styles.skillsContainer}>
                  <SkillWidget skill="Greek Mythology in Film" />
                  <SkillWidget skill="Video Game Music" />
                  <SkillWidget skill="E-Commerce Entrepreneurship" />
                </div>
                {/* <p className={styles.bodyStyle}>
            Fun fact: I was admitted to the engineering school as a chemical
            engineering major before I decided to switch to CS after an
            introductory programming course.
          </p> */}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
});

export default Education;
