import React from "react";
import styles from "./SideBar.module.css";
import SideBarText from "./SideBarText";
import pfp from "../static/pfpSquare.jpg";
import { SocialIcon } from "react-social-icons";

const SideBar = ({ refObject }) => {
  return (
    <div className={styles.sideBarBox}>
      <img alt="pfp" className={styles.imgStyle} src={pfp} />
      <SideBarText refObject={refObject} />
      <div className={styles.socialIcons}>
        <SocialIcon
          style={{ marginRight: "10px" }}
          url="https://github.com/josephlee3150"
          target="_blank"
        />
        <SocialIcon
          style={{ marginRight: "10px" }}
          url="https://www.linkedin.com/in/joseph-lee-b252211a5/"
          target="_blank"
        />
      </div>
    </div>
  );
};

export default SideBar;
